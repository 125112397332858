/*----------------------------------------*/
/*    18 - Components - Button
/*----------------------------------------*/
.obrien-button{
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    font-family: $general-font-family-name;
    height: 45px;
    letter-spacing: 0.025em;
    line-height: 45px;
    padding: 0 30px;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    width: auto;
    transition: all 0.3s ease 0s;
    border-radius: 25px;
    @include breakpoint(max-xs_device){
        font-size: 14px;
        padding: 0 25px;
    }
    @include breakpoint(max-xxs_device){
        font-size: 12px;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
    }
    &.primary-btn{
        background-color: $primary-color;
        color: $white-color;
        &:hover{
            background-color: $border-color-2;
            color: #1b1b1c;
        }
    }
    &.white-btn{
        background-color: #d8d8d8;
        color: #1b1b1c;
        &:hover{
            background-color: $primary-color;
            border-color: $primary-color;
            color: $white-color;
            transition: .3s;
        }
    }
    &.black-btn{
        background-color: $black-color;
        border-color: $black-color;
        color: $white-color;
        &:hover{
            background-color: $primary-color;
            border-color: $primary-color;
            color: $white-color;
            transition: .3s;
        }
    }
    &:focus{
        box-shadow: none;
    }
}
.obrien-button-2{
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    font-family: $body-font-family-name;
    letter-spacing: 0.025em;
    padding: 8px 25px;
    text-transform: capitalize;
    text-align: center;
    vertical-align: middle;
    width: auto;
    transition: all 0.3s ease 0s;
    border-radius: 25px;
    @include breakpoint(max-xs_device){
        padding: 8px 15px;
    }
    &.primary-color{
        color: $white-color;
        background: $primary-color;
        &:hover{
            color: $black-color;
            background: $border-color-2;
        }
    }
    &:focus{
        box-shadow: none;
    }
    &.treansparent-color{
        background:transparent;
        border: 1px solid $border-color;
        height: 45px;
        line-height: 45px;
        @include breakpoint(max-xxs_device){
            height: 40px;
            line-height: 40px;
        }
        &:hover{
            color: $primary-color;
            border-color: $primary-color;
        }
    }
}
.obrien-button-3{
    border-radius: 4px;
    border: none;
    box-shadow: 0 0 0 0 transparent;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    width: 100%;
    transition: background 0.2s ease-in-out;
    padding: 16px 32px;
    &.black-button{
        background-color: #1B1B1C;
        width: 335px;
        @include breakpoint(max-xxs_device){
            width: 280px;
        }
        &:hover{
            background-color: $primary-color;
            color: $white-color;
        }
    }
}