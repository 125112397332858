/*----------------------------------------*/
/*    23 - Components - Newsletter 
/*----------------------------------------*/
.home-1{
    .newsletter-area{
        margin-top: 80px;
        @include breakpoint(max-xxs_device){
            margin-top: 60px;
        }
    }
}
.newsletter-area{
    background: #f2f2f2 url(../images/bg/1-1.jpg)no-repeat scroll center center / cover;
    height: 430px;
    .section-content{
        .title-3{
            margin-bottom: 8px;
        }
        .title-4{
            margin-bottom: 14px;
        }
        .desc-content{
            margin-bottom: 45px;
        }
    }
    .newsletter-form-wrap{
        width: 90%;
        .form-control{
            border-radius: 25px;
            height: 45px;
            line-height: 45px;
            padding-right: 155px;
            border-color: $default-color !important;
            background-color: transparent;
            @include breakpoint(max-xs_device){
                padding-right: 125px;
            }
            @include breakpoint(max-xxs_device){
                height: 40px;
                line-height: 40px;
                padding-right: 100px;
                font-size: 12px;
            }
            &:focus{
                box-shadow: none;
                border: 1px solid $primary-color !important;
            }
        }
        .newsletter-btn{
            right: 0;
        }
    }
}