/* =============================================
=                CSS Index            =
============================================= */
/*

    00 - Base - Variables
    00 - Base - Mixins
    01 - Global - Default
    02 - Global - Helper
    03 - Global - Color
    04 - Layout - Header
    05 - Layout - Footer
    06 - Pages - Blog
    07 - Pages - Shop
    08 - Pages - Single-product
    09 - Pages - Contact
    10 - Pages - Error
    11 - Pages - Compare
    12 - Pages - Cart
    13 - Pages - Checkout
    14 - Pages - Wishlist
    15 - Pages - FAQ
    16 - Pages - My-Account
    17 - Pages - Login-Register
    18 - Components - Button
    19 - Components - Slider
    20 - Components - Feature
    21 - Components - Product
    22 - Components - Banner
    23 - Components - Newsletter 
    24 - Components - Support
    25 - Components - Action-plan
    26 - Components - Brand-logo
    27 - Components - Modal
    28 - Components - Breadcrumb

  =====  End of CSS Index  ====== */

@import "0-base/variables";
@import "0-base/mixins";
@import "1-global/default";
@import "1-global/helper";
@import "1-global/color";
@import "2-layout/header";
@import "2-layout/footer";
@import "3-pages/blog";
@import "3-pages/shop";
@import "3-pages/single-product";
@import "3-pages/contact";
@import "3-pages/error";
@import "3-pages/compare";
@import "3-pages/cart";
@import "3-pages/checkout";
@import "3-pages/wishlist";
@import "3-pages/faq";
@import "3-pages/my-account";
@import "3-pages/login-register";
@import "4-components/button";
@import "4-components/slider";
@import "4-components/feature";
@import "4-components/product";
@import "4-components/banner";
@import "4-components/newsletter";
@import "4-components/support";
@import "4-components/action-plan";
@import "4-components/brand-logo";
@import "4-components/modal";
@import "4-components/breadcrumb";