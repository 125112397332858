/*----------------------------------------*/
/*    10 - Pages - Error
/*----------------------------------------*/
.error-area{
    margin-top: 70px;
    margin-bottom: 80px;
}
.error_form{
    text-align: center;
    & h1{
        font-size: 200px;
        font-weight: 700;
        color: $primary-color;
        letter-spacing: 10px;
        line-height: 160px;
        margin: 0 0 52px;
        @include breakpoint(max-md_device){
            font-size: 130px;
            line-height: 120px;
            letter-spacing: 4px;
            margin: 0 0 40px;
        }
    }
    & h2{
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 20px;
        @include breakpoint(max-md_device){
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 14px;
        }
    }
    & p{
       font-size: 17px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 30px; 
        @include breakpoint(max-md_device){
            font-size: 15px;
            margin-bottom: 22px;
            line-height: 21px
        }
    }
    & form{
        width: 450px;
        margin: 0 auto;
        position: relative;
        @include breakpoint(max-sm_device){
            width: 100%;
        }
        & input{
            padding: 0 60px 0 30px;
            background: #f8f8f8;
            border: 1px solid #ddd; 
            color: $default-color;
            height: 40px;
            width: 100%;
            border-radius: 3px;
            
        }
        & button{
            position: absolute;
            right: 0;
            height: 100%;
            border: none;
            background: no-repeat;
            font-size: 20px;
            cursor: pointer;
            transition: .3s;
            top: 0;
            text-transform: uppercase;
            padding: 0 15px;
            font-weight: 600;
            &:hover{
                color: $primary-color;
            }
        }
    }
    & a{
        color: $white-color;
        display: inline-block;
        background: $primary-color;
        font-size: 12px;
        font-weight: bold;
        height: 40px;
        line-height: 40px;
        padding: 0 30px;
        text-transform: uppercase;
        margin-top: 35px;
        border-radius: 3px;
        @include breakpoint(max-md_device){
            padding: 0 20px;
            margin-top: 25px;
        }
        &:hover{
            background: $default-color;
        }
    }
}

.error_section {
	padding: 55px 0 70px;
    @include breakpoint(max-md_device){
        padding: 25px 0 70px;
    }
    @include breakpoint(max-md_device){
      padding: 14px 0 60px;
    }
}
/*404 page css end*/