/*----------------------------------------*/
/*    00 - Base - Variables
/*----------------------------------------*/
//--- All Variables - Related Stuff Included In This Area ---↓
// project specific variables
$theme-color--default: #303030;
$default-color:        #1B1B1C; 

// Color
$primary-color:     #E98C81;
$footer-color:      #D8D8D8;
$text-color:        #303030;
$heading-color:     #1B1B1C;
$theme-black-color: #1b1b1b;
$bg-color-1:        #2d2d2d;
$bg-color-2:        #f3f3f3;
$border-color:      #e1e1e1;
$border-color-2:    #d8d8d8;
$border-color-3:    #a8a8a8;
$border-color-4:    #e5e5e5;
$black-color:       #000000;
$blockquote-bg:     #f8f9fc;


// Additional Color Variable
$white-color:          #ffffff;
$white-smoke-color:    #f0edee;
$white-smoke-color-2:  #efedee;
$chyan-color:          #666666;

// Social Color Variable
$facebook:  #3B5998;
$twitter:   #55ACEE;
$linkedin:  #2867B2;
$pinterest: #DF0022;

// Font Family
$body-font-family-name: 'Poppins', sans-serif;
$general-font-family-name: 'Oswald', sans-serif;